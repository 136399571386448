import React, { useState } from 'react'
import './journey.css'
import {Container} from 'react-bootstrap'
import Navbar from './navbar'
import zigzag from '../images/zigzag.png'
import signature from '../images/signature.jpeg'
import Logo from './logo'

const Journey = () => {

  const [ContentDiv,setContentDiv] = useState(false)

  const handleShow = () => {
    setContentDiv(true)
  }
  const handleHide = () => {
    setContentDiv(false)
  }

  return (
    <>
      <Container className='journey_container'>
        <Logo />
        <Navbar />
        <div className='content_container'>
          <div className='button_container'>
            <button onClick={handleShow}>Roadmap 1</button>
            <button>Roadmap 2</button>
            <button>EARNINGS</button>
          </div>
        </div>
      {ContentDiv && 
        <div className='content_div'>
          <div className='main_journey_heading'>
            <div className='journey_heading'>
              <img src={zigzag} />
              <p>Educated Cats</p>
            </div>
            <span onClick={handleHide}>X</span>
          </div>
          {/* <div className='journey_content'>A brand for the meta-verse built on a collection of 6666 cats. <br/><br/> These cats are born very smart and know exactly how to make money for their owners. It features a strategic racing action game as run by Solana-based blockchain. It deploys a play-to-earn mechanism where players can monetize their time and earn real money (stable coin) leveraging on a seamless platform for easy withdrawal of profits. Educated Cats holders get a free NFT for the P2E game and have access to members-only benefits the first of which is, ability to borrow NFT to other users for daily profits, sell of NFT in premium marketplace and 10% yearly community profit sharing for 3 years (counts after game released).<br/><br/> After 01.11.2022 we will make 500 Solana available just for our community.<br/><br/> So they gonna decide in our Discord group in which upcoming NFT projects they wanna invest. And every quarter they will get 70% of profits from the investments and 30% willl go to the team treasury.<br/> With a vibrant community, a young and excited team that promises holders, a more rewarding experience and continous exclusive benefits for being a part of our vision. So snatch them up now and proudly lay your claim for a seat at the cool kid's table.<br/><br/> Educated Cats, the radical idea of the next level gaming experience. Built on a community where no one is left behind and audacity is giving back to the community.</div>   */}
          {/* <div className='journey_content'>
            <p style={{fontSize:'1em'}}>A brand for the meta-verse and web3 built on a collection of 6666 cats.</p><br /><br />
            <p>Well, how will it work?</p><br />
            We gonna solve one of the most important tool in NFT space that is <span className='span'>marketing</span> for upcoming projects on Web3!<br />
            The people will be able to present their projects on our website for our community. The projects will not have to run anymore from one promoter to another one and spend thousands of money without knowing if their projects can be really shown to protential investors or not. 
            Instead they just gonna list their project on our website and present it to our community. The community will get notified from our team and they gonna vote if the project is interesing for them or not.<br />
            Except our community, the team of EducatedCats will also have an eye on good projeects to invest in. <br /><br />
            <p>But what will be the benefits for our community?</p>
            They can easily find gems with potential to invest in. <br />
            The projects in order to get presented on our website, need to pay a listing fee of 500 USDT to get presented to our community. <br />
            We will also offer them promote their projects on our website in spacific areas by chanrging extra fees. <br />
            50% of all these revenus we share with our community monthly. <br /><br />
            And after 01.12.2022 if we sold out we gonna make 500 Solana available for our community to decide together in which projects on web3 space we should invest in. <br />
            And each month we gonna share 50% of our wins that we generated through this investings with our holders.
          </div> */}
          <div className='journey_content'>
            <p>A brand for the Meta-Verse and Web3 that is built on a collection of 6666 Educated Cats.</p><br />
            <div>What is the main purpose of the Educated Cats team?</div>
            <p>A young team from Frankfurt Germany, building a cool community and brand for the Meta-Verse and Web3.</p><br />
            We want to be the first marketing agency on NFT space that is built from it’s community for it’s community.
            And we gonna <p>bring our P2E game in AR/VR that you have never seen before.</p><br/>
            <div><bold>*</bold>Our main goal is:</div>
            <p><bold>*</bold>to bring our P2E game on Meta-Verse to you where you can build a team, monetize your time and earn real money</p>
            <p>(<span>stable-coin</span>), just by playing the game.</p><p><bold>*</bold>to solve the <span>marketing</span> problem currently facing NFT projects.</p><br />
            <div>How are we going to implement these ideas?</div>
            Currently, developers and owners with very good NFT projects do not have enough money to spend on marketing thereby <p>failing to reach their goals because they can not find real investors to invest their NFTs.</p><p> This is what we want to change! </p><br />
            <p><bold>1.</bold> Firstly, our official website will be transformed into a modern marketing website for upcoming NFT projects. These NFT projects will be able to easily list and present their projects to our community who</p><p> are potential investors. And our marketing team gonna even manage some unique projects.</p> <br/>
            <p>More so, the Educated Cats team will make <span>250 Solana</span> available for the team to invest in upcoming NFT projects. Our community will then vote on our <span>Discord</span> channel, in which projects the team should invest for them.</p><br/>
            <p><span>30%</span> of all profits generated through these means go to the community and <span>70%</span> to the Educated Cats team </p><p> and our partners.</p><br/>
            <p><bold>2.</bold> Secondly after the sell-out, we will begin the launch of our designed P2E game to the Meta-Verse.</p><br />
            <p>The game features a strategic racing-action game that runs on the Solana Blockchain. </p>
            <p>It deploys a play-to-earn mechanism where players can monetize their time and earn real money</p><p> (<span>stable-coin</span>), leveraging on a seamless platform for easy withdrawal of profits.</p><br/>
            Educated Cats holders get a free NFT for the P2E game and have access to members-only benefits the first of which is, the ability to borrow NFT to other users for daily profits, sell of NFT in the premium 
            marketplace, and <span>10%</span> yearly community <p>profit-sharing for <span>3 years</span> (counts after the game released).
            We gonna start on our P2E game after 01.06.2023.</p><br/>
            Educated Cats, the radical idea of the next level gaming experience. Built on a community where no one is left behind and <p> audacity is giving back to the community.</p>
          </div>
          <div className='signature_container'>
            <p>Sam H</p>
            <span>01.10.2022</span>
            <div>
              <img src={signature} />
            </div>
          </div>
        </div>}
        <div className='journey_footer'>
            <p>© 2022, Educated Cats no one will be left behind!</p>
        </div>
      </Container>
    </>
  )
}

export default Journey