import React, { useEffect, useState } from 'react'
import './gallery.css'
import Navbar from './navbar'
import zigzag from '../images/zigzag.png'
// import CEO from '../images/owner.jpeg'
// import CEO from '../images/owner1.jpeg'
// import CEO from '../images/owner2.png'
import signature from '../images/signature.jpeg'
import slide1 from '../images/Unique Ones/Ice.png'
import slide2 from '../images/Unique Ones/Emerald.png'
import slide3 from '../images/Unique Ones/Snake.png'
import slide4 from '../images/Unique Ones/In Darkness.png'
import slide5 from '../images/Unique Ones/Visible Body.png'
import slide6 from '../images/Unique Ones/Fire.png'
import { AiFillCaretRight,AiFillCaretLeft } from "react-icons/ai";
import Logo from './logo'

const Gallery = () => {

  useEffect(()=>{
    var slides = document.querySelectorAll(".slide");
    var buttons = document.querySelectorAll(".button")
    console.log(buttons,'r');
    let count = 0

    buttons[0].addEventListener("click",()=>{
      buttons[0].style.color = "#319ad7"
      buttons[0].style.background = "#61d6f32f"
      setTimeout(()=>{
        buttons[0].style.color = "white"
        buttons[0].style.background = "#0F0F0F"
      },500)
      count++
      if(count > 5){
        buttons[0].style.color = "white"
        buttons[0].style.background = "#0F0F0F"
        buttons[1].style.color = "#319ad7"
        buttons[1].style.background = "#61d6f32f"
        setTimeout(()=>{
          buttons[1].style.color = "white"
          buttons[1].style.background = "#0F0F0F"
        },500)
        count = 5
      }
      console.log(count,'r');
      slides.forEach(e=>{
        e.classList.remove("active")
      })
      slides[count].classList.add("active")
    })

    buttons[1].addEventListener("click",()=>{
      buttons[1].style.color = "#319ad7"
      buttons[1].style.background = "#61d6f32f"
      setTimeout(()=>{
        buttons[1].style.color = "white"
        buttons[1].style.background = "#0F0F0F"
      },500)
      count--
      if(count < 0){
        buttons[1].style.color = "white"
        buttons[1].style.background = "#0F0F0F"
        buttons[0].style.color = "#319ad7"
        buttons[0].style.background = "#61d6f32f"
        setTimeout(()=>{
          buttons[0].style.color = "white"
          buttons[0].style.background = "#0F0F0F"
        },500)
        count = 0
      }
      slides.forEach(e=>{
        e.classList.remove("active")
      })
      slides[count].classList.add("active")
    })

  })
  return (
    <>
      <div className='gallery_container'>
        <div className='gallery_main'>
          <div className='gallery_leftSide'></div>
          <div className='gallery_rightSide'></div>
        </div>
        <div className='gallery'>
          <div className='gallery_logo'>
            <img src={zigzag} />
          </div>
          <Logo />
          <Navbar />
          <div className='gallery_content'>
            <div className='left_content'>
              <h1> 6666 pieces out of 140+ hand drawn traits with a lot of love and passion</h1>
              <p>6 legendary characters</p>
              <p>1 legendary = 50 Sol</p>
              <p>There is 6 unique characters hidden in the collection, if you mint any of them you will get 50 Solana as reward</p>
            </div>
            <div className='right_content'>
              <div className='slider_container'>
                <AiFillCaretRight className='button' />
                <div className='slide1'></div>
                <div className='slide active'>
                  <img src={slide1} />
                </div>
                <div className='slide'>
                  <img src={slide2} />
                </div>
                <div className='slide'>
                  <img src={slide3} />
                </div>
                <div className='slide'>
                  <img src={slide4} />
                </div>
                <div className='slide'>
                  <img src={slide5} />
                </div>
                <div className='slide'>
                  <img src={slide6} />
                </div>
              <AiFillCaretLeft className='button button1' />
              </div>
            </div>
          </div>
          {/* <div className='CEO_container'>
            <div className='CEO'>
              <img src={CEO} />
            </div>
            <div className='CEO_content2'>Sammy Harif</div>
          </div> */}
          </div>
        <div className='journey_footer' style={{borderTop:"1px solid #333"}}>
            <p>© 2022, Educated Cats no one will be left behind!</p>
        </div>
      </div>
    </>
  )
}

export default Gallery