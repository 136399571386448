import React from 'react'
import Gallery from './components/gallery'
import Home from './components/home'
import Journey from './components/journey'
import Manifesto from './components/manifesto'
import {BrowserRouter,Routes,Route} from 'react-router-dom'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/manifesto' element={<Manifesto />}></Route>
          <Route path='/journey' element={<Journey />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App