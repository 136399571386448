import React from 'react'
import './logo.css'
import { Link } from 'react-router-dom'
import logo from '../images/Logo1.png'
import lightText from '../images/lightText.png'
import brightText from '../images/brightText.png'

const Logo = () => {
  return (
    <div>
        <Link to='/'>
          <div className='logo'>
              <img src={logo} />
          </div>
        </Link>
        <div className='text_container'>
          <img className='lightText' src={lightText} />
          <img className='brightText' src={brightText} />
        </div>
    </div>
  )
}

export default Logo