import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {
  return (
    <div className='main_navbar'>
        {/* <div className='navbar'>
            <ul>
                <li><a href='#'>Home</a></li>
                <li><a href='#'>Manifesto</a></li>
                <li><a href='#'>Journey</a></li>
                <li><a href='#'>Gallery</a></li>
            </ul>
        </div> */}  
        <Link to='/' style={{textDecoration: 'none'}}>
          <div className='items'>
            <h1 className='item_content'>H</h1>
            <div className='item'>Home</div>
          </div>
        </Link>
        <Link to='/manifesto' style={{textDecoration: 'none'}}>
          <div className='items'>
            <h1 className='item_content'>M</h1>
            <div className='item'>Manifesto</div>
          </div>
        </Link>
        <Link to='/journey' style={{textDecoration: 'none'}}>
          <div className='items'>
            <h1 className='item_content'>J</h1>
            <div className='item'>Journey</div>
          </div>
        </Link>
        <Link to='/gallery' style={{textDecoration: 'none'}}>
          <div className='items'>
            <h1 className='item_content'>G</h1>
            <div className='item'>Gallery</div>
          </div>
        </Link>
    </div>
  )
}

export default Navbar