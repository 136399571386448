import React from 'react'
import {Container} from 'react-bootstrap'
import './manifesto.css'
import Navbar from './navbar'
import zigzag from '../images/zigzag.png'
import whiteArrow from '../images/whiteArrow.png'
import blackArrow from '../images/blackArrow.png'
import Logo from './logo'

const Manifesto = () => {
  return (
    <Container className='main_container'>
        <div className='container_main'>
          <Logo />
          <Navbar />
          <div className='manifesto_container'>
            <div className='manifesto_heading'>
              <img src={zigzag} />
              <p>Manifesto</p>
            </div>
            <div className='manifesto_content'>
              The  game freaks, the Nerds, the square pegs in round holes.<br/>
              The ones who think differently and don’t respect status quo,<br/>
              The ones who changes things.<br/>
              You are welcome the way you are but be nice.<br/>

              It starts by hodling the NFT,<br/>
              Demanding investment as a means not an end.<br/>
              Daring into the world of possibilities,<br/>
              By making capital work for us and not control us.<br/>

              It requires patience, a hard-edge hope <br/>
              And doing what’s right not what’s easy.<br/>
              So be with the grind because the future is bright<br/> 
              And the goals, ONE.<br/>

              Educated Cats, the radical idea of the next level gaming experience <br/>
              Built on a community where no one is left behind.<br/>
            </div>
          </div>
          <div className='arrow_container'>
            <img src={blackArrow} />
            <img src={whiteArrow} />
          </div>
          <div className='footer'>
              <p>© 2022, Educated Cats no one will be left behind!</p>
          </div>
        </div>
    </Container>
  )
}

export default Manifesto