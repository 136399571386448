import React from 'react'
import './home.css'
import {Container} from 'react-bootstrap'
import Navbar from './navbar'
import icon1 from '../images/icon1.svg'
import icon2 from '../images/icon2.svg'
import icon3 from '../images/icon3.svg'
import soldout from '../images/2.png'
import mintday from '../images/1.png'
import { Link } from 'react-router-dom'
import Logo from './logo'

const Home = () => {
  return (
    <Container className='main_container'>
      <Logo />
        <Navbar />
        <div className='icons_container'>
         <a className='icon' href='https://twitter.com/educatedcatsio' target="_blank" rel="noreferrer">
          <img src={icon1} alt=""/>
         </a>
         <a className='icon' href='' >
          <img src={icon2} alt=""/>
         </a>
         <a className='icon' href='' >
          <img src={icon3} alt=""/>
         </a>
        </div>
                  {/*
        <div className="soldOut_container">

          <img className='soldout' src={soldout} />
          <img className='mintday' src={mintday} />
    
        </div>
        <div className='btn_container'>
          <button>Mint</button>
        </div>
              */}
        <div className='footer'>
            <p>© 2022, Educated Cats no one will be left behind!</p>
        </div>
    </Container>
  )
}

export default Home